.signUpContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eaeaea;
}

.signUpBody {
  flex: 1;
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.signUpLabel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.signUpHeading {
  display: flex;
  flex-direction: column;
}

.signUpHeading hr {
  width: 100%;
  height: 1px;
  background-color: #252a34;
}

.signUpForm {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: flex-start;
  width: 300px;
  border: 1px solid #252a34;
  padding: 2em;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 #252a34;
}

.signUpFormSubmit {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.signUpForm div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}

.googleSignInButton {
  background-color: white;
  border: 1px solid #252a34;
  border-radius: 5px;
  padding: 0.8em 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
  cursor: pointer;
}

.signUpButton {
  background-color: #252a34;
  color: #eaeaea;
  border: none;
  border-radius: 5px;
  padding: 1em 1.5em;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s;
}

.signUpButton:hover {
  transform: scale(1.12);
}

.passwordContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.passwordIcon {
  position: absolute;
  right: 4px;
  top: 3px;
  cursor: pointer;
}

.errorMessage {
  color: red;
}

.googleIcon {
  width: 30px;
  height: 30px;
}

.logInSpan {
  font-style: italic;
  text-decoration: underline;
  color: #ff2e63;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .signUpBody {
    padding: 0 6% 0 6%;
    margin-top: 20%;
    margin-bottom: 20%;
  }
  .signUpHeading h1 {
    font-size: x-large;
  }

  .signUpLabel p {
    font-size: small;
  }

  .signUpLabel hr {
    width: 100%;
  }

  .signUpForm div {
    flex-direction: column;
    align-items: flex-start;
  }

  .googleSignInButton {
    padding: 0.5em 1em;
  }

  .googleIcon {
    width: 20px;
    height: 20px;
  }

  .signUpButton {
    /* padding: 0.5em 1em; */
    font-weight: 600;
  }

  .errorMessage {
    font-size: small;
  }
}
