.aboutUsContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eaeaea;
}

.aboutUsBody {
  flex: 1;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 0 6% 0 6%;
  margin-bottom: 5%;
}

.teamMemberList {
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  gap: 3em;
}

.teamMemberImage {
  width: 230px;
  height: 300px;
  border-radius: 50%;
}

.teamMember {
  border: 1px solid #252a34;
  border-radius: 5px;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  height: 500px;
  /* border-radius: 5px;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 230px;
  height: 300px; */
}

.profession {
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .aboutUsBody {
    padding: 0 6% 0 6%;
    margin-top: 20%;
  }

  .aboutUsBody h1 {
    font-size: larger;
  }

  .aboutUsBody p {
    font-size: small;
  }

  .teamMemberImage {
    width: 80%;
    border-radius: 50%;
  }

  .teamMemberList {
    margin-top: 10%;
    flex-direction: column;
  }

  .teamMember {
    width: 90%;
    height: 90%;
  }
}
