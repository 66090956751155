.emailVerifyContainer {
  background-color: #eaeaea;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.emailVerifyBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
  margin-bottom: 20%;
}

.emailVerifyMessage {
  border: 1px solid #252a34;
  border-radius: 5px;
  padding: 2%;
}

.emailVerifyLogin {
  color: #ff2e63;
  cursor: pointer;
  text-decoration: underline;
}
@media screen and (max-width: 768px) {
  .emailVerifyBody {
    padding: 0 6% 0 6%;
  }
}
