.faqContainer {
  display: flex;
  justify-content: space-evenly;
}
.faqList {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.faqItem {
  border: 1px solid #252a34;
  display: flex;
  flex-direction: column;
  width: 800px;
  border-radius: 5px;
  margin-bottom: 4%;
  text-align: left;
  padding: 1em;
}

.faqQuestion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2%;
}
.faqImage {
  width: 20px;
  height: 20px;
  /* width: 10%;
  height: 10%; */
}

.faqItem p {
  color: #48484b;
}

@media only screen and (max-width: 768px) {
  .faqContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .faqItem {
    width: 90%;
  }

  .faqItem p {
    padding: 2%;
    font-size: small;
  }
}
