.recipeContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eaeaea;
}

.recipeBody {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  margin-top: 5%;
  margin-bottom: 8%;
}

.recipeCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.noSavedRecipe {
  color: #252a34;
}

.savedRecipesContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
@media screen and (max-width: 768px) {
  .recipeBody {
    padding: 0 6% 0 6%;
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .savedRecipesContainer {
    gap: 1em;
  }

  .savedRecipeImage {
    width: 50%;
  }

  .noSavedRecipe {
    font-size: small;
  }
}
