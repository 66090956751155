.privacyContainer {
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  justify-content: center;
}

.privacyBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  margin-top: 8%;
  margin-bottom: 8%;
}

.privacyBodyContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 40%;
}

.privacySignOff {
  font-size: small;
  color: #353638;
}

@media screen and (max-width: 768px) {
  .privacyBody {
    padding: 0 6% 0 6%;
    margin-top: 20%;
    margin-bottom: 20%;
    /* justify-content: center; */
  }

  .privacyBodyContent {
    width: 100%;
  }
  .privacyBodyContent h1 {
    font-size: x-large;
  }

  .privacyBodyContent p {
    font-size: small;
  }
}
