.loginContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eaeaea;
}

.loginBody {
  flex: 1;
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loginHeading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.loginHeading hr {
  width: 100%;
}

.signUpSpan {
  color: #ff2e63;
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
}

.emailLogin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}

.passwordLogin {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
}

.loginButton {
  background-color: #252a34;
  color: #eaeaea;
  border: none;
  border-radius: 5px;
  padding: 1em 1.5em;
  cursor: pointer;
  transition: transform 0.3s;
  font-weight: 600;
}

.loginButton:hover {
  transform: scale(1.12);
}

.loginHeading hr {
  width: 100%;
  height: 1px;
  background-color: #252a34;
}

.validationMessage {
  text-align: left;

  word-wrap: break-word;
  white-space: normal;
}

.errorMessage {
  word-wrap: break-word;
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid #252a34;
  border-radius: 5px;
  padding: 2em;
  box-shadow: 0 0 5px 0 #252a34;
  overflow: hidden;
  width: 300px;
  /* margin-top: 10%; */
}

.loginFormSubmit {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.errorMessageLogin {
  display: none;
}

.errorMessageLogin.displayError {
  display: block;
}

.emailLoginContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.forgotPasswordLink {
  /* font-size: small; */
  color: #ff2e63;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .loginBody {
    align-items: center;
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .loginHeading h1 {
    font-size: x-large;
  }

  .loginHeading p {
    font-size: small;
  }

  .forgotPasswordLink {
    font-size: small;
  }
}
