.footerContainer {
  background-color: #252a34;
  padding: 0.5% 0 0 0;
  display: flex;
  flex-direction: column;
}

.newFooter {
}

.brandName h3 {
  color: #eaeaea;
}

.footerLogoImg {
  width: 50px;
  height: 50px;
  /* margin: 10% 0 0 0; */
}

/* .footerLeft {
  width: 100%;
  display: flex;
  flex-direction: column;
} */

.footerLogo {
  display: flex;
  gap: 1em;
  width: max-content;
  justify-content: center;
  align-items: center;
}

.footerLinks {
  display: flex;
  gap: 1em;
  font-weight: 600;
  justify-content: center;
}

.footerLinks p {
  color: #eaeaea;
  cursor: pointer;
  transition: transform 0.3s;
  font-size: small;
}

.footerLinks p:hover {
  transform: scale(1.12);
}

/* .footerRight {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
} */

/* .footerContact p {
  color: #eaeaea;
  font-size: small;
} */

.footerCopyright p {
  color: #eaeaea;
  font-size: small;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .footerContainer {
    flex-direction: column;
    align-items: center;
    gap: 1em;
    justify-content: center;
  }

  .footerLeft {
    align-items: center;
    justify-content: center;
  }

  .footerLinks {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerRight {
    align-items: center;
    text-align: center;
  }

  /* dashboard footer */
  .dashboardFooterContainer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #eaeaea;
    border-top: 1px solid #252a34;
    /* padding: 2% 0 2% 0; */
  }

  .dashboardFooterContainer img {
    width: 40px;
    height: 40px;
  }

  .signOutSettings {
    background-color: #252a34;
    color: white;
    position: fixed;
    right: 8%;
    top: 88%;
    padding: 0.5em 1em;
    border: none;
    border-radius: 5px;
  }

  .homeFooterActive {
    background-color: #acacac;

    /* border-radius: 5px; */
  }

  .homeFooter {
    flex: 1;
    padding: 2% 0 2% 0;
  }
  .recipeFooter {
    flex: 1;
    padding: 2% 0 2% 0;
  }

  .settingsFooter {
    flex: 1;
    padding: 2% 0 2% 0;
  }
  .settingsFooterActive {
    background-color: #acacac;
    /* border-radius: 5px; */
  }

  .recipeFooterActive {
    background-color: #acacac;
    /* border-radius: 5px; */
  }

  .settingsDropdownSignOut {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 88%;
    left: 55%;
    border: 1px solid #252a34;
    padding: 0 2% 0 2%;
    border-radius: 5px;
    background-color: #acacac;
  }

  .settingsDropdown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: fixed;
    top: 81%;
    left: 55%;
    border: 1px solid #252a34;
    padding: 0 2% 0 2%;
    border-radius: 5px;
    background-color: #acacac;
  }

  .settingsDropdown hr {
    width: 100%;
    height: 1px;
    background-color: #252a34;
    border: none;
  }

  .faIcons {
    width: 40px;
    height: 40px;
    color: #252a34;
  }
}
