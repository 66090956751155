.dashBoardContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eaeaea;
}

.dashboardBody {
  flex: 1;
  margin-top: 8%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 4% 0 4%;
  margin-bottom: 8%;
}
.dashBoardTop {
  display: flex;
  margin-bottom: 2%;
}

.dashBoardTopLeft {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.dashBoardTopRight {
  display: flex;
}

.captureDashboardButton {
  background-color: #ff2e63;
  border: none;
  border-radius: 5px;
  color: #eaeaea;
  padding: 1em 1.5em;
  font-weight: 600;
}

.dashboardHr {
  width: 100%;
  height: 1px;
  background-color: #252a34;
}
.dashBoardTopLeftTags {
  display: flex;
  gap: 1em;
}

.ingredientTag {
  list-style: none;
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  background-color: #eaeaea;
  border: 1px solid #252a34;
  border-radius: 5px;
  padding: 0.5em 1em;
}

.ingredientsInput {
  display: flex;
  gap: 0.5em;
}

.removeIngredientTag {
  background-color: #ff2e63;
  border: none;
  border-radius: 5px;
  color: white;
  font-weight: 700;
  cursor: pointer;
}
.addIngredients {
  background-color: #252a34;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 0.5em 1em;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.3s;
}
.addIngredients:hover {
  transform: scale(1.12);
}

.addIngredients[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.dashBoardTitle {
  font-size: 1.2em;
  font-weight: 600;
  text-decoration: underline;
}

.dashBoardResults {
  margin-top: 1%;
}
@media screen and (max-width: 768px) {
  .dashboardBody {
    padding: 0 6% 0 6%;
    margin-top: 20%;
    margin-bottom: 20%;
  }
  .dashBoardTop {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashBoardTopLeft {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashBoardTopLeftHeading {
    text-align: left;
  }

  .dashBoardTopRight {
    flex-direction: column;
    align-items: flex-start;
  }

  .dashBoardTopRight {
    text-align: left;
  }

  .orDiv {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .cameraDashboard {
    width: 30px;
    height: 30px;
  }

  .captureDashboardButton {
    padding: 0.5em 1em;
  }

  .ingredientTag {
    padding: 0.3em 0.6em;
  }

  .ingredientTag li {
    font-size: small;
  }

  .addIngredients {
    font-size: small;
  }

  .note {
    font-size: small;
  }

  .dashBoardTitle {
    font-size: 1.2em;
    font-weight: 600;
  }
}
