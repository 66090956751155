.recipeInfoContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eaeaea;
}

.recipeInfoBody {
  flex: 1;
  display: flex;
  padding: 1em;
  flex-direction: column;
  align-items: center;
  margin-top: 8%;
  margin-bottom: 8%;
}

.backIcon {
  cursor: pointer;
}

.recipeInfoTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8em;
}

.recipeTitleLeft {
  display: flex;
  justify-content: center;
  align-items: center;
}

.saveRecipe {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.recipeSaveIcon {
  cursor: pointer;
}

.recipeSaveContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.recipeInstructions {
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 1em; */
  text-align: left;
}

.recipeInfoInstructions {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  width: 600px;
  margin-top: 6%;
}

.recipeInfoIngredients {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  margin-top: 6%;
}

.saveRecipeButton {
  background-color: #6f7072;
  color: #eaeaea;
  padding: 0.5em 1em;
  border: 2px solid #252a34;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.unsaveRecipeButton {
  background-color: #eaeaea;
  color: #252a34;
  border: 2px solid #252a34;
  padding: 0.5em 1em;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}

.notification {
  color: #252a34;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

.nutrientsInfo {
  display: flex;
  gap: 0.5em;
}

.nutrientsInfo p {
  font-size: small;
}

.readyInInfo {
  font-size: small;
}

.readyInfoAndNutrients {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.backIcon {
  width: 80px;
  height: 80px;
}

.recipeSaveIcon {
  width: 40px;
  height: 40px;
}

.recipeInfoImage {
  width: 80%;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .recipeInfoInstructions {
    width: 100%;
    align-items: flex-start;
  }

  .recipeInstructions {
    align-items: flex-start;
  }

  .recipeInfoBody {
    flex-direction: column;

    margin-top: 20%;
    margin-bottom: 20%;
  }

  .recipeInfoTitle {
    flex-direction: column;

    width: 100%;
    gap: 1em;

    align-items: flex-start;
  }

  .recipeTitleLeft {
    display: flex;
  }

  .recipeTitleLeft h3 {
    font-size: medium;
  }

  .backIcon {
    width: 40px;
    height: 40px;
  }

  .recipeInfoImage {
    width: 50%;
    border-radius: 5px;
  }

  .recipeSaveIcon {
    width: 30px;
    height: 30px;
  }

  .notification {
    font-size: small;
  }

  .recipeInfoIngredients {
    align-items: flex-start;
  }
}
