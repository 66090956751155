.recipeResultsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 1em;
}

.recipeResultsDisplay {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}

.nomatches {
  font-size: small;
  color: #535353;
}

.recipeResultsDisplay {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  gap: 1.5em;
}
.recipeCard {
  /* background-color: aqua; */
}

.recipeImage {
  /* width: 200px;
  height: 200px; */
  border-radius: 5px;
}

.recipeCard {
  display: flex;
  border: 1px solid #252a34;
  border-radius: 5px;
  padding: 1em;
}
@media screen and (max-width: 768px) {
  .recipeResultsDisplay nomatches {
    font-size: small;
  }

  recipeResultsDisplay {
    gap: 1em;
  }

  .recipeImage {
    /* width: 100px;
    height: 100px; */
  }

  .recipeTitle {
    font-size: 1.1em;
    font-weight: 600;
  }

  .recipeCard {
    flex-direction: column;
  }
}
