.homeContainer {
  background-color: #eaeaea;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.homeBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12em;
  padding: 1% 4% 1% 4%;
  margin-top: 10%;
}

.homeIntro {
  display: flex;
  justify-content: space-around;
}

.homeIntroLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
}

.homeIntroLeft .homeIntroHeading h1 {
  background: conic-gradient(
    from 210deg at 50% 50%,
    #ff2e63 120.6000030040741deg,
    #252a34 360deg
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.homeIntroHeading hr {
  border: 0;
  height: 2px;
  background-color: #252a34;
  width: 100%;
}

.tryItButton {
  background-color: #ff2e63;
  color: #eaeaea;
  padding: 1em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: transform 0.3s;
}

.homeIntroButtons {
  display: flex;
  gap: 1em;
}

.tryItButton:hover {
  transform: scale(1.12);
}

.homeIntroSignInButton {
  background-color: #252a34;
  color: #eaeaea;
  padding: 1em 1.5em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s;
  font-weight: 600;
}

.homeIntroSignInButton:hover {
  transform: scale(1.12);
}

.ingrediensImage {
  /* object-fit: cover; */
}

.homeHowItWorks {
  /* border: 2px solid #252a34; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2% 0 2%;
  border-radius: 5px;
}

/* .howItWorksItem {
  display: flex;

  gap: 2em;
} */

.howItWorksItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 500px;
}

.howItWorksSteps {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  /* flex-direction: column;
  align-items: center;
  gap: 1em; */
}

.howItWorksList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2em;
}

.stepOneHowItWorks {
  /* background: linear-gradient(to right, #252a34, #252a34); */
  /* border: 1px solid #252a34; */
  box-shadow: 0 0 5px #ff2e63;
  padding: 2em;
  border-radius: 10px;
}

.stepTwoHowItWorks {
  /* background: linear-gradient(to right, #252a34, #252a34); */
  /* border: 1px solid #252a34; */
  box-shadow: 0 0 5px #252a34;
  padding: 2em;
  border-radius: 10px;
}
/* .stepOneText {
  color: #eaeaea;
} */

.stepThreeHowItWorks {
  /* background: linear-gradient(to right, #252a34, #252a34); */
  /* border: 1px solid #252a34; */
  box-shadow: 0 0 5px #ff2e63;
  padding: 2em;
  border-radius: 10px;
}

.howItWorksImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;
}
.howItWorksImage {
  border: 2px solid #909091;
  border-radius: 5px;
  width: 60%;
  margin: 0;
}

.howItWorksSteps {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
}

.howItWorksSteps p {
  color: #48484b;
  font-weight: 500;
}

.tiktokSection {
  display: flex;
  text-align: left;
}

.testimoniesSection {
  border: 2px solid #252a34;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 2% 0 2%;
}

.testimoniesList {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 200px;
}

.testimony {
  width: 100%;
  /* background-color: #252a34; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.testimonyUser p {
  color: #636365;
}
.faqSection {
  margin-bottom: 5%;
}
@media only screen and (max-width: 400px) {
}

@media only screen and (max-width: 768px) {
  .homeBody {
    /* padding: 1% 4% 1% 4%; */
    margin-top: 20%;
    gap: 4em;
  }
  .homeIntro {
    flex-direction: column;
    align-items: center;
    gap: 2em;
  }

  .homeIntroLeft {
    align-items: center;
    text-align: center;
  }

  .homeIntroHeading h1 {
    font-size: 2.5em;
  }

  .homeIntroButtons {
    /* flex-direction: column; */
    gap: 1em;
  }

  .ingredientsImage {
    width: 70%;
  }

  .homeIntroHeading h1 {
    font-size: 2em;
  }

  .homeIntroLeft p {
    font-size: 1em;
  }

  .howItWorksList h4 {
    font-size: small;
  }

  .howItWorksItem {
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    width: 80%;
  }

  .howItWorksImage {
    width: 100%;
  }

  .homeHowItWorks h3 {
    font-size: medium;
  }
  .howItWorksList p {
    font-size: small;
  }

  .tiktokSection {
    flex-direction: column;
  }

  .tiktokSectionLabel h3 {
    font-size: medium;
  }

  .tiktokSectionLabel p {
    font-size: small;
  }
  .tiktokSectionImage {
    width: 25%;
  }

  .tiktokSectionImageContainer {
    display: flex;
    justify-content: center;
  }

  .testimoniesSection h3 {
    font-size: medium;
  }

  .testimony p {
    font-size: small;
  }

  .arrow {
    width: 6%;
    height: 8%;
  }
}
