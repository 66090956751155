.forgotPasswordContainer {
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  background-color: #eaeaea;
}

.forgotPasswordBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.forgotPasswordMessage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1em;
  text-align: left;
  border: 1px solid #252a34;
  border-radius: 5px;
  padding: 2em;
}

.forgotPasswordForm {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: flex-start;
}

.forgotPasswordButton {
  background-color: #252a34;
  border: none;
  border-radius: 5px;
  color: #eaeaea;
  padding: 1em 1.5em;
  font-weight: 600;
}

.resetPasswordLogin {
  color: #ff2e63;
  cursor: pointer;
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .forgotPasswordBody {
    padding: 0 6% 0 6%;
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .forgotPasswordMessage h1 {
    font-size: x-large;
  }

  .resetPasswordLoginLabel {
    font-size: small;
  }
}
