.landingNavContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.5% 2% 0 2%;
  position: fixed;
  width: 100%;
}

.scrolled {
  background-color: #e1e1e1;
  transition: background-color 0.5s ease-in-out;
}
.navLinksContainer {
  display: flex;
  justify-content: space-around;
  width: 20%;
  list-style: none;
  margin-right: 2%;
}

.dashLogo {
  display: flex;
  padding: 0 2% 0 2%;
  align-items: center;
}
.landingLogo {
  width: 50px;
  height: 50px;
  margin: 0 0 0 0;
  cursor: pointer;
  transition: transform 0.3s;
}

.landingLogo:hover {
  transform: scale(1.12);
}

.navLink {
  opacity: 0.5;
  cursor: pointer;
  transition: transform 0.3s;
}

.navLink:hover {
  opacity: 0.8;
  transform: scale(1.12);
}

.active {
  opacity: 1;
  font-weight: 500;
}

.navHr {
  display: none;
  border: 0;
  height: 2px;
  background-color: #ff2e63;
}

.display {
  display: block;
}

.signOutDropDown {
  position: fixed;
  background-color: #373838;
  color: #eaeaea;
  padding: 1em;
  top: 5%;
  right: 1%;
  border-radius: 5px;
}

.signOutDropDown p {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .landingNavContainer {
    padding: 0.5% 5% 0 5%;
  }

  .navLinksContainer {
    flex-flow: column nowrap;
    background-color: #252a34;
    position: fixed;
    top: -1em;
    padding: 6% 0 0 6%;
    right: 0;
    height: 100vh;
    width: 300px;
    justify-content: flex-start;
    gap: 2em;
    align-items: flex-start;
    display: none;
    transition: transform 0.5s ease-in-out;
    transform: translateX(100%);
  }

  .navLinksContainer.open {
    display: flex;
    transform: translateX(0%);
    transition: transform 0.5s ease-in-out;
  }

  .navLinksContainer li {
    color: #eaeaea;
  }

  .navHr {
    width: 60px;
  }

  .landingLogo {
    width: 40px;
    height: 40px;
    padding-top: 20%;
  }

  .dashLogo {
    align-items: baseline;
  }
}

/* dashboard nav */

.dashboardNavContainer {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* padding: 0 2% 0 2%; */
  border-bottom: 1px solid #252a34;
}

.dashboardUl {
  display: flex;
  list-style: none;
  gap: 2em;
  padding-right: 2%;
}

.dashboardNav {
  opacity: 0.5;
  cursor: pointer;
  transition: transform 0.3s;
  margin-bottom: -5px;
}

.dashboardNav:hover {
  opacity: 0.8;
  transform: scale(1.12);
}

.dashboardNav.dashActive {
  opacity: 1;
  /* font-weight: 500; */
}
